.report-card {
    box-shadow: 0px 0px 0px 1px rgb(215, 215, 215);
  }

  .report-card:hover {
    /* box-shadow: 0px 0px 0px 2px #4a70c2; */
    box-shadow: 0px 0px 0px 2px rgb(69, 144, 214);
  }
  .report-search-bar div input {
      background-color: #f4f4f6 !important
  }
  .report-search-bar div .row {
      background-color: #f4f4f6 !important
  }
  .datasource-search-bar div div {
    background-color: white;
    min-height: 30px !important;
  }
  .datasource-search-bar div div div {
    height: 0 !important;
  }
  .datasource-search-bar {
    border-bottom: .5px solid #f2f2f2;
  }
  .datasource-search-bar input {
    padding: 8px 15px;
  }
  .user-permission-popup .popup-header .fs-xl {
      font-size: 14px;
      font-weight: 600;
  }