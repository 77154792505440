.dk-report-chart-container .dk-data-grid-container {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.dk-hover-container .dk-hover-action-button,
.widget-name-container .widget-name-action {
    display: none;
}

.dk-hover-container:hover .dk-hover-action-button,
.widget-name-container:hover .widget-name-action {
    display: inline-block;
}