:root {
  --button-theme: rgb(84, 163, 235);
  --app-theme: rgb(51, 59, 78);
  --text-theme: rgb(90, 100, 215);
  --border-theme: rgb(51, 59, 78);
}

/* background themes */
.bg-app {
  background-color: var(--app-theme);
}

.bg-button,
div.dk-bg-button,
button.dk-bg-button {
  background-color: var(--button-theme);
}

/* Text themes */
.text-app {
  color: var(--button-theme);
}

/* Border themes */
.border-app {
  border: 1px solid var(--border-theme);
}
