/* SPACINGS: margin/paddings */
.mt-auto {
    margin-top: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}


/* DISPLAY UTILS: position, display, component/screen width */
.full-screen-width {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100vh;
}

/* TEXT FORMAT UTILS */
.text-ellipsis {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* RESPONSIVE UTILS */
.max-width-100 {
    max-width: 100%;
}

.min-height-parent {
    min-height: 100%;
}

@media screen and (max-width: 800px) {
    /* SPACINGS */
    .mobile-p-0 {
        padding: 0px !important;
    }

    .mobile-m-0 {
        margin: 0px !important;
    }

    /* DISPLAY/SIZE */
    .mobile-parent-width {
        width: 100% !important;
    }

    .mobile-max-width-parent {
        max-width: 100% !important;
    }

    .mobile-auto-height {
        height: auto !important;
    }

    /* FLEX STYLES */
    .mobile-justify-content-start {
        justify-content: flex-start !important;
    }

    .mobile-justify-content-center {
        justify-content: center !important;
    }

    .mobile-align-items-center {
        align-items: center !important;
    }
    
    .mobile-justify-content-end {
        justify-content: flex-end !important;
    }

    .mobile-justify-content-start {
        justify-content: flex-start !important;
    }

    .mobile-flex-wrap {
        flex-wrap: wrap;
    }

    .mobile-flex-gap-s {
        gap: 8px;
    }

    .mobile-flex-gap-m {
        gap: 16px;
    }
}

@media screen and (max-width: 560px) {
    .mobile-full-width {
        width: 100% !important;
    }
}